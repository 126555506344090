"use client";

import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";
import ServicePage from "@/components/service/servicePage";
export default function Error({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <>
      <ServicePage data={{
      title: "Something went wrong",
      text: {
        content: [{
          type: "paragraph",
          content: [{
            type: "text",
            text: "An error has occurred while loading this part of the page."
          }],
          block_key: "error-1"
        }, {
          type: "paragraph",
          content: [{
            type: "text",
            text: "You can try again or return to the homepage."
          }],
          block_key: "error-2"
        }]
      },
      buttons: [{
        type: "link_button",
        id: "home",
        value: {
          theme: "primary",
          label: "Return to Evident",
          href: "/",
          open_in_new_tab: false
        }
      }],
      reset: reset
    }} data-sentry-element="ServicePage" data-sentry-source-file="error.tsx" />
    </>;
}